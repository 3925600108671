<template>
  <div style="padding: 60px;font-size: 20px;font-weight: 600;">

    正在登录中,请稍等...
  </div>
</template>

<script>
import {
  loginCAS, loginFast
} from "@/api/index.js";
import {setStore} from "@/libs/storage";

export default {
  name: "cas",
  data() {
    return {
      ytoken: "",
      headerT: "",
      headerS: "",
    };
  },
  mounted() {
    this.ytoken = this.$route.query.ytoken;
    this.headerT = this.$route.query.t;
    this.headerS = this.$route.query.s;
    if(this.ytoken) {
      loginCAS({ytoken: this.ytoken, t: this.headerT}).then((res) => {
        if (res.status) {
          setStore("accessToken", res.data.token);
          setStore("userInfo", res.data);
          this.$router.push("/home");
        } else {
          this.$router.push("/pages/error-500");
        }
      });
    } else if(this.headerT && this.headerS) {
      this.loginFast()
    }
  },
  methods: {
    loginFast() {
      let fastObj = {
        t: this.headerT,
        s: this.headerS,
      };
      if (this.headerS && this.headerT) {
        loginFast(fastObj).then((res) => {
          if (res.status) {
            setStore("accessToken", res.data.token);
            setStore("userInfo", res.data);
            this.$router.push("/home");
          } else {
            this.$router.push("/pages/error-500");
          }
        });
      }
    },
  },
};
</script>
